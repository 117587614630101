import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["data-bs-original-title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createTextVNode(_toDisplayString(_ctx.user?.fullName) + " ", 1),
    (_ctx.user?.id)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "symbol symbol-25px symbol-circle",
          "data-bs-toggle": "tooltip",
          title: "",
          "data-bs-original-title": _ctx.user?.fullName
        }, [
          _createVNode(_component_Avatar, {
            avatar: _ctx.user?.avatar,
            "user-id": _ctx.user?.id
          }, null, 8, ["avatar", "user-id"])
        ], 8, _hoisted_1))
      : _createCommentVNode("", true)
  ], 64))
}