
import {defineComponent} from 'vue'
import Avatar from "@/components/base/common/Avatar.vue";

export default defineComponent({
  name: "UserLink",
  components: {Avatar},
  props: {
    user: {}
  }
})
