
import {defineComponent, ref, watch} from "vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import {CLAIM_APPROVAL} from "@/core/config/Constant";
import ClaimService from "@/core/services/ClaimService";
import UserListSelect from "@/components/common/UserListSelect.vue";

export default defineComponent({
  name: "ClaimApprovalForm",
  components: {UserListSelect, BaseRadio, BaseForm},
  props: {
    id: {},
    claim: {},
  },
  emits: ['saved'],
  setup(props) {
    const submitting = ref(false);
    const model = ref(props.claim);
    watch(() => props.claim, cb => {
      model.value = cb;
    })
    return {
      submitting,
      model,
      CLAIM_APPROVAL,
    }
  },
  methods: {
    onSubmit() {
      this.submitting = true;
      ClaimService.updateApproval(this.id, this.model).then(res => {
        this.$emit('saved', res);
      }).finally(() => {
        this.submitting = false
      })
    }
  }
})
